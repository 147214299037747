import { PureComponent } from 'react'
import { FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa'

class WelcomeText extends PureComponent {

    render() {
        return (
            <div className="welcome-text">
                <p>👋 Hi, there, I'm</p>
                <p>Daniel Kalevski</p>
                <p>Software developer, passionate about developing web applications and games using <span>JavaScript</span>.</p>
                <a href="/resume.pdf">Download CV</a>
                <div className="social-links">
                    <a href={this.props.githubLink || '/'}><FaGithub size={48}/></a>
                </div>
            </div>
        )
    }

}

export default WelcomeText