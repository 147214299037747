import Header from "../containers/Header"
import Projects, { ProjectItem } from "../containers/Projects"
import WelcomeText from "../containers/WelcomeText"

const HomePage = () => (
    <main className="kalevski container">
        <Header />
        <div className="row content">
            <section className="col-lg-7">
                <WelcomeText
                    githubLink="https://github.com/kalevski"
                />
            </section>
            <aside className="col-lg-5">
                <Projects>
                    <ProjectItem 
                        title="phaser-plus"
                        linkTo="https://github.com/kalevski/phaser-plus"
                        tags={['library']}
                        description="Extensions to make PhaserJS more awesome"
                    />
                    <ProjectItem 
                        title="rivalis"
                        linkTo="https://github.com/kalevski/rivalis"
                        tags={['library']}
                        description="NodeJS library for building real-time applications"
                    />
                    <ProjectItem 
                        title="toolcase"
                        linkTo="https://github.com/kalevski/toolcase"
                        tags={['library']}
                        description="A collection of JavaScript modules useful for any kind of JavaScript application"
                    />
                </Projects>
            </aside>
        </div>
        
    </main>
)

export default HomePage