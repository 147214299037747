import { PureComponent } from 'react'

const Logo = () => (
    <div className="component logo">
        <img src="/images/logo.png" alt="kalevski.dev logo" />
        <h1>KALEVSKI<span>.dev</span></h1>
    </div>
)


class Header extends PureComponent {

    render() {
        return (
            <header className="row header">
                <div className="col-md-8 logo">
                    <Logo />
                </div>
                <div className="col-md-4 contact">

                </div>
            </header>
        )
    }

}

export default Header