import { PureComponent } from 'react'

const ProjectBox = ({ children }) => (
    <div className="component project-box">
        <h2>Projects</h2>
        <ul>{children}</ul>
    </div>
)

export const ProjectItem = ({ linkTo, title, tags = [], description }) => (
    <li>
        <a href={linkTo}>
            <h2>{title}</h2>
        </a>
        {tags.map((tag, index) => <span key={index}>{tag}</span>)}
        <p>{description}</p>
    </li>
)

class Projects extends PureComponent {

    render() {
        return (
            <ProjectBox>
                {this.props.children}
            </ProjectBox>
        )
    }

}

export default Projects